@import '../../Variables';

.sliderShow {
	width: 100%;
	height: 100%;
}
.slide {
	width: 100%;
	margin-top: 20px;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	img {
		object-fit: contain;
		width: 100%;
		height: 60vh;
	}

	p {
		font-size: 1.3em;
		font-weight: bold;
		color: $gronze-primary-color;
		text-align: center;
	}

	h1, h2, h3, h4, h5 {
		text-align: center;
	}

	h3 {
		font-size: 2.5em;
	}

	.logo {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		> div {
			width: 150px;
			height: 150px;
		}
	}
}
.sliderViewport {
	position: relative;
	top: 0;
	bottom: 0;
	height: 100%;
}
.sliderContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 20px;
	margin: auto;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
}
.sliderNavButtons {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 20px;
	margin: auto;
	z-index: 2;
}
.sliderDotButtons {
	position: absolute;
	bottom: 20px;
	left: 20px;
	right: 20px;
	margin: auto;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		width: 16px;
		height: 16px;
		border-radius: 10px;
		background: #ffffff;
		border: 1px solid $gronze-primary-color;
		margin: 0 5px;

		&.selected {
			background: $gronze-primary-color;
		}
	}
}

@media (min-width: 600px) and (orientation: landscape) {
	.slide {
		gap: 10px;
	}
	.sliderDotButtons {
		bottom: 10px;
	}
}

@media (min-width: 1000px) and (orientation: landscape) {
	.slide {
		gap: 10px;
	}
	.sliderDotButtons {
		bottom: 10px;
	}
}

