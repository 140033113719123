/*
* LogManager SnackBar custom properties
 */
@import '../Variables';

.snackBar {
  z-index: 9998;
  max-height: 500px;

  .danger {
    background-color: #d32f2f;
  }
  .warning {
    background-color: #ffa000;
  }
  .info {
    background-color: $gronze-light-brown;
  }
  .success {
    background-color: #43a047;
  }

  &.hide {
    max-height: 0px;
    overflow: hidden;
    bottom: -300px;
    transition: bottom 1s, max-height 2s;
  }
}
