.page {
	width: 100vw;
	height: 100dvh;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);

	.cancel-icon {
		top: env(safe-area-inset-top);
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}
	.icon {
		transform: scale(1.6);
		margin: 35px 25px 15px 15px;
	}
	.gronzeButton {
		margin: 2em auto;
		width: 100%;
	}
	h5 {
		font-size: 1.2em;
		margin-top: 0.5em;
	}
	h4 {
		font-size: 1.8em;
		line-height: 1em;
		margin-top: 0.6em;
		margin-bottom: 0.4em;
	}
	h3 {
		font-size: 1.55em;
		margin-top: 0.5em;
	}
	h2 {
		font-size: 1.75em;
		margin-top: 0.3em;
		margin-bottom: 0.2em;
	}
	h1 {
		font-size: 2em;
		font-weight: bold;
		margin-top: 0.65em;
		padding: 0 20px;
	}
	ul  {
		margin-left: 1em;
		padding-left: 0;

		li {
			margin-left: 1em;
			list-style-type: disc;
		}
	}
	a {
		position: relative;
		color: #8e571f;

		&:after {
			background: url("../../assets/icons/openExternal.png");
			content: "";
			width: 14px;
			height: 14px;
			display: inline-block;
			margin-left: 4px;
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: 4px;
			top: 2px;
			position: relative;
		}
	}
}
