   /*
   * Satellite button as Mapboxscale IControl
   */
.mapboxgl-ctrl .button_sat {
  &.standard {
    margin: 0;
    background-image: url("../icons/icon_plano.png");
  }
  &.sat {
    margin: 0;
    background-image: url("../icons/icon_sat.png");
  }
}
