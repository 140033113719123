/**
 * Card info - Extra styles.
 */
@import '../Variables';

.MuiCardHeader-root {
  padding: 8px 8px 4px 8px !important;
}

.swype-line {
  opacity: 0.2;
  width: 40px;
  margin: 8px auto 0;
  padding: 2px;
  background-color: #000000;
  cursor: pointer;
}

.card-content {
  width:100%;
  max-height:100%;
  //overflow-y:auto;
  margin:0;
  // Put some padding at the bottom to skip buttons.
  padding: 0 0 60px 0;

  .nota-destacada {
      margin-bottom: $buttons-right-margin;
       svg {
           vertical-align: bottom;
       }
   }
  .servicios-container {
      width: 100%;
      padding: 8px 4px;
        .servicio:last-child {
            margin-right: 0;
        }      
  }
  
  .gronze-rating-colorbar {
      height:24px;
      background:#999999; /* gronze gray */
      font-size:16px;
      color:white;

    .votes {
      float:left;
      height:100%;
      text-align:center;
      padding-top:0.2em;
    }
    .votes-up {
      background:#6a923e; /*gronze light green */
      float:left;
    }
    .votes-down {
      background:#de1e00;
      float:right;
    }
  }
  
  // Stage / top data
  .field .starrating {
    display:inline-block; 
  }
  .stage-data .field {
    float:left;
    width: 50%;
  }
  .node-etapas .field-name-field-texto-intro {
    clear:left;
    margin-top: 8px;
  }
  
}

.field {
  margin-top: 4px;
}
.label-inline,
.field-label-inline .field-label {
  display: inline-block;
  font-size:1.1em;
  font-weight:bold;
}
.label-above {
  margin-top: 4px;
  font-size:1.1em;
  font-weight:bold;
}

.glyphicons {
  margin-right: 4px;
}


