#initSessionCard {

  .content {

    button {
      margin: 10px 10px;
    }
    ul {
      list-style: disc inside;
    }
  }
  .MuiGrid-container {
    margin: 0;
  }
  a {
    margin: 10px;
  }
}
