@import '../Variables';

.menu {
  position: relative;
  width: 70vw;
  z-index: 10000;
  padding-top: env(safe-area-inset-top);


  .cancel-icon {
    top: env(safe-area-inset-top);
  }

  .title {
    padding-left: 10px;
  }

  .brand {
    font-size: .8em;
    text-align: right;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 70vw;
    background-color: #fff;
    margin: 0;
    padding: 2px 10px;
  }

  .userPicture {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    display: inline-block;
    margin: 10px;
    margin-bottom: 0;
    vertical-align: bottom;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    display: inline-block;
  }

}
