.mapboxgl-canvas-container {
  .icon.glyphicons {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
    }

    &.gronze-icon-albergue {
      &:before {
        background: url("assets/icons/albergue.svg");
      }
    }
    &.gronze-icon-alojamiento {
      &:before {
        background: url("assets/icons/alojamiento.svg");
      }
    }
  }
}

