#initSessionCard {

  .title {
    padding-left: 50px;
    text-align: center;
    margin-top: 15px;
  }
  .MuiGrid-container {
    margin: 10px 0;
  }
  .legal-conditions {
    text-align: center;

    .MuiGrid-container {
      margin: 0;
    }
    a {
      margin: 10px;
    }
  }
}
