.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	height: 100dvh;
	background-color: rgba(0,0,0,0.3);
}
.cancelIcon {
	top: 4px;
}

.modalInside {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 20px;
	border-radius: 20px;
	background: rgb(244, 242, 240);
	height: 80vh;
	margin: auto;
	z-index: 10000;
	box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
}

@media (min-width: 600px) and (orientation: landscape) {
	.modalInside {
		max-width: 350px;
		height: 90vh;
	}
}

@media (min-width: 1000px) and (orientation: landscape) {
	.modalInside {
		max-width: 500px;
		height: 90vh;
	}
}
