.outOfMap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 20px;
	top: 0;
	bottom: 0;
	position: absolute;
	text-align: center;
	width: 100%;

	h5 {
		font-size: 1.2em;
		margin-top: 0.5em;
	}
	h4 {
		font-size: 1.8em;
		line-height: 1em;
		margin-top: 0.6em;
		margin-bottom: 0.4em;
	}
	h3 {
		font-size: 1.55em;
		margin-top: 0.5em;
	}
	h2 {
		font-size: 1.75em;
		margin-top: 0.3em;
		margin-bottom: 0.2em;
	}
	h1 {
		font-size: 2em;
		font-weight: bold;
		margin-top: 0.65em;
		padding: 0 20px;
	}
	ul  {
		padding-left: 0;
		margin-top: 15px;
		margin-bottom: 15px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;

		li {
			list-style: none;
			color: #8e571f;
			font-size: 1.3em;
			font-weight: bold;

			button {
				font-size: 0.7em !important;
				width: 100%;
				text-transform: none;
			}
		}
	}
	.title {
		font-size: 1.0em;
	}
	.message {
		font-size: 0.8em;
	}
}
