/*
Card info
 */
@import '../Variables';

.cardContainer {
    position: relative;
    width: 100vw;
    height: 100dvh;
    bottom: -100vh;
    left: 0;
}

// Also MuiPaper-root Muicard-root MuiPaper-rounded
.detailCard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9997;
    height: 30vh;
    border-radius: 4px;
    box-shadow: 2px -2px 4px rgba(0,0,0,0.2);
    padding: 0;

    /* detailCard with Image */
    &.imageCard {
        height: 50vh;

        &.show {
            top: -50vh;
        }
        .cardMedia {
            max-height: 20vh;
            transition: max-height 0.5s;

            img {
                width: 100vw;
            }
        }
        .cancelIcon {
            svg {
                color: #fff;
            }
        }
    }
    &.show {
        top: -30vh;
        transition: top 0.5s, height 0.5s !important;
        .cardActions {
            bottom: 0;
            padding-bottom: env(safe-area-inset-bottom);
            transition: all 0.5s 0.3s !important;
        }
    }
    &.expanded {
        height: 100dvh;
        top: -100vh;
        transition: top 0.5s !important;

        .cardContentWrapper {
            display: flex;
            flex-direction: column;
            height: 100dvh;
            padding-top: calc(env(safe-area-inset-top) - 8px);
            box-sizing: border-box;
        }
        .cardContent {
            overflow-y:auto;
            height: auto;
            margin-bottom: 70px;
        }
        .cardActions {
            bottom: 0;
            padding-bottom: env(safe-area-inset-bottom);
            transition: all 0.5s !important;
            overflow: hidden;
        }
        &.imageCard {
            height: 100dvh;
            top: -100vh;
            .cardMedia {
                max-height: 30vh;
                transition: max-height 0.5s;
            }
            transition: height 1s;
        }
        .infoContainer {
            padding-bottom: calc(env(safe-area-inset-bottom));
        }
    }
    .cardContent {
        // For scroll bar, needs height and...
        //height: calc(30vh - 60px);
        height: 30vh;
        //overflow-y:auto;

        margin: 6px 0 0 0;
        margin-top: 0px;
        box-sizing: border-box;

        padding: 8px !important;
        display: flex;
        align-items: baseline;
        flex-direction: column;
        position: relative;
    }
    .disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    .infoContainer {
        width: 100%;
    }
    .cancelIcon {
        top: calc(8px + env(safe-area-inset-top));
    }
}

/* detailCard with Errors */
.errorCard {

    .cardActions {
        justify-content: space-around;
    }

}

.cardMedia {
    max-height: 25vh;
    overflow: hidden;
    width: 100vw;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .image {
        display: block;
    }
}

/**
 * Card Buttons: Select, Prev, Next
 */
.selectButtonsBox {
    //justify-content: stretch;
    justify-content: space-between;
    padding-top:4px;
}

.selectButton {
    white-space: nowrap;
    align-self: center;
}

.prevButton:not([disabled]) {
    color: $gronze-light-brown !important;
    align-self: flex-end;
}
.nextButton:not([disabled]) {
    color: $gronze-light-brown !important;
    align-self: flex-start;
}

.mediumSizeIcon {
    margin-top: -4px;
}

.cardActions {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + -20vh);
    right: 0;
    max-height: calc(env(safe-area-inset-bottom) + $actions-height);
    padding: 0 8px;
    transition: all 0.5s !important;
    background-color: #FFF;
    width: 100vw;
    display: flex;
    overflow: auto;

    .actionsContainer {
        display: flex;
        min-height: min-content;
        width: 100vw;
        flex-direction: row;
        justify-content: flex-end;
    }
    .bookingButton,
    .bookingButton:hover {
        color: $booking-color;
        font-weight: bold;
        font-size: 1.2em;
    }
    .gronzeButton {
        white-space: nowrap;
        font-weight: bold;
        font-size: 1.2em;
    }
    /*
		a {
			box-shadow: none;

			&.link-localidad {
				.green-arrow {
					color: $primary-color;
					transform: scale(-1.3, 1.3) translate(-5px, 4px);
				}
			}
			.link-localidad-text {
				display: inline-block;
			}
		}
		*/
}

.hotel {
    .cardActions {
        .actionsContainer {
            margin: auto;
        }
    }
}

@media (orientation: landscape) {
    .cardContainer {
        bottom: 0;
        top: 0;
        left: -100vw;
    }

    .cardContentWrapper {
        padding-left: env(safe-area-inset-left);
    }

    .detailCard {
        width: 50vw;
        top: 0 !important;
        height: 100dvh !important;
        transition: left 0.5s !important;

        .cardActions {
            left: -50vw;
            width: 50vw;
            transition: left 0.5s !important;
        }
        &.imageCard {
            bottom: 0;
        }
        &.show {
            left: 100vw !important;
            transition: left 0.5s !important;
            .cardActions {
                left: 0;
                transition: left 0.5s 0.3s !important;
                bottom: 0px;
                padding-bottom: 20px;
            }
        }
    }
    .cardContent {
        height: calc(100vh - #{$actions-height} - 6px);
    }
    .imageCard {

        .cardMedia {
            max-height: 50vh;
            margin-top: 0;
            width: 50vw;

            img {
                width: 50vw;
            }
        }

        .cardContent {
            height: calc(50vh - #{$actions-height} - 6px);
        }
    }

    .cancelIcon {
        top: 8px;
    }
}


@media (orientation: portrait) {
    .detailCard {
        transition: top 0.5s !important;
    }
}

