$actions-height: 54px;
$buttons-right-margin: 5px;
$primary-color: #719848;
$booking-color: #003580;

/* From themes/gronze2016/less/gronze-variables.less */
$gronze-green: #6a923e;
$gronze-dark: #333333;
$gronze-gray: #999999;
$gronze-light-gray: #eeeeee;
$gronze-light-green: #729848;
$gronze-brown: #5f3411;
$gronze-dark-brown: #492705;
$gronze-mid-brown: #6e3e0e;
$gronze-light-brown: #8e571f;

$gronze-primary-color: #5f3411;
