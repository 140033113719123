/**
 * App.css - Layout
 */
@import './Variables';
@import './Icons';
@import './android';
@import './ios';

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  overscroll-behavior: none;
}

/**
 * Elements go like:
 *
 * #app-wrapper > #app-container > (#app)
 */
#app-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  background-color: #ffffff;
  overflow: hidden;
}

#app-container {
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

header {
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  position: fixed;
  top: calc(8px + env(safe-area-inset-top));
  left: 10px;
  z-index: 999;
  transition: left 0.5s;

  .logo {
    padding: 0px;
    height: 48px;
    border-radius: 5px;
    pointer-events: none;

    &.active {
      cursor: pointer;
      pointer-events: auto;
    }
  }
}

.logo-maps {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/logo-maps.svg");
}

/**
 * Some generic card styles
 */
.cancel-icon{
  position: absolute;
  top: calc(4px + env(safe-area-inset-top));
  right: 4px;
  opacity: .7;
  cursor: pointer;

  &.card {
    top: 8px;
  }
  svg {
    width: 30px;
    height: 30px;
    color: #666;
  }
}
.expanded {
  .cancel-icon{
    top: calc(8px + env(safe-area-inset-top));
  }
}

/**
 * Blocking Card (Offline, GeoPermissionFail)
 */
.MuiCard-root.blockingCard {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 10px;

    border-radius: 4px;
    box-shadow: 2px -2px 4px rgba(0,0,0,0.2);
    background-color: #fff;

    .card-actions {
        position: fixed;
        bottom: 0;
        right: 0;
        max-height: $actions-height;
    }
}

/*
 * Offline cloud icon and button
 */
.MuiSvgIcon-root.cloud-icon {
  display: block;
  height: auto;
  width: 100%;
  max-width: 60px;
  margin: 0;
}
.MuiButton-root.big-button {
  font-size: 1.75em;
  font-weight: bold;
}

.MuiButtonBase-root.MuiButton-root {
  font-size: 1.00rem;
  padding: 10px 10px;

  span {
    pointer-events: none;
  }
}
.MuiInputBase-input.MuiInput-input {
  min-height: 48px;
  line-height: 48px;
  font-size: 1rem;
  color: #000000;
}

/*
 * Menú container
 */
.MuiDrawer-root.MuiDrawer-modal {
  z-index: 100000 !important;
}

.appComponent .MuiSnackbar-root {
  z-index: 10000;
  bottom: calc(8px + env(safe-area-inset-bottom));
}

@media (orientation: landscape) {
  #app-container {
    width: 100vw;
    left: 0;
    transition: left 0.5s;
  }
  #app-container.detailView .mapComponent .mapboxgl-map {
    left: 50vw;
    transition: left 0.5s;
  }
  .detailView {
    header {
      left: calc(10px + 50vw);
    }
  }
  .menu {
    padding-left: env(safe-area-inset-left);
  }
}

.circularProgress {
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.mapComponent {
  .mapboxgl-control-container {
    .mapboxgl-ctrl-top-left {
      top: env(safe-area-inset-top);
    }
    .mapboxgl-ctrl-top-right {
      top: calc(10px + env(safe-area-inset-top));
      .mapboxgl-ctrl {
        margin: 0 10px 10px 0;
      }
    }
    .mapboxgl-ctrl-bottom-left {
      bottom: env(safe-area-inset-bottom);
      .mapboxgl-ctrl-scale {
        bottom: calc(6px + env(safe-area-inset-bottom));
      }
    }
    .mapboxgl-ctrl-bottom-right {
      bottom: env(safe-area-inset-bottom);
    }
  }
}

/**
  Workaround for App buttons not clickable,
  https://gitlab.com/gronze/maps/-/issues/358
*/

#app-container.detailView .MuiSnackbar-root {
  display:none;
}
