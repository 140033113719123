
@import '../Variables';

#initSessionCard {
  overflow: auto;
  height: 100dvh;

  .title {
    padding-left: 50px;
    text-align: center;
    width: 100%;
    padding-top: 15px;
    position: absolute;
    background: #ffffff;
    padding-bottom: 10px;

    small {
      font-size:60%;
      font-style:italic;
      vertical-align: super;
    }
  }

  .content {
    padding: 10px;
    box-sizing: border-box;
    padding-top: 80px;

    button {
      margin: 20px 10px;
    }
    ul {
      list-style: disc inside;
    }
  }
  .MuiGrid-container {
    margin: 10px 0;
  }
  a {
    margin: 10px;
  }

  .mainIcon {
      font-size: 60pt;
  }

  .footer {
    position: relative;
    bottom: 1em;
    left: 0;
    right: 0;
    margin-top: 20px;

    a {
      text-align: center;
      display: inline-block;
      margin: 0;
      line-height: 26px;
      font-size: 1rem;
    }
  }

  .ios-register-link {
    display: block;
    margin: 1em;
  }

}
