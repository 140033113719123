
@media (orientation: portrait) {
  .iphone {
    #initSessionCard {
      margin-top: 20px;

      .title {
        margin-top: 0;
        padding-top: 30px;
      }
      .content {
        padding-top: 0;
        margin-top: 75px;
      }
    }
  }
}

@media (orientation: landscape) {
  .iphone.notch {
    .detailView header {
      left: calc(10px + 50vw);
    }
    .mapboxgl-ctrl-top-right {
      top: 10px;
      right: 20px;
    }
    .MuiPaper-root.MuiCard-root {
      &.expanded{
        padding-top: 35px;

        .cancel-icon {
          top: 35px;
        }
      }
      .MuiCardHeader-content {
        padding-left: 20px;
      }
    }
    .menu {
      .title {
        padding-left: 25px;
      }
    }
    .page {
      padding-top: 25px;
      padding-left: 30px;
      padding-right: 30px;

      .header {
        top: 25px !important;
      }
      .cancel-icon {
        top: 25px !important;
        right: 8px;
      }
    }

  }
}
