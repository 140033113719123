/**
 * MapComponent.scss - Global styles for maps
 */

@import "SatelliteControl";
@import "StageControl";

.mapComponent {
  
  /**
   * Map buttons (right)
   */
  .buttonBar {
    position: absolute;
    right: 10px;
    top: 150px;
  }
  
  .button {
    border-radius: 5px;
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.2);
    box-sizing: border-box;
    background-color: #fff;
    text-align: center;
    line-height: 30px;
    color: #000;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  /*
   * Mapboxscale bar position
   */
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-scale {
    float: none;
    position: fixed;
    bottom: 6px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
  }
  
  
  /**
   * Accommodation (Hotels on the map)
   */
  .accommodation {
    font-size: 20px;
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 20px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  
    &.selected{
      width: 42px;
      height: 42px;
      z-index: 100;

      .accomodation-name {
        font-weight: bold;
        margin-left: 15px;
      }
    }

    .accomodation-name {
      position: absolute;
      min-width: 130px;
      font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
      font-size: 0.7em;
      left: 34px;
      top: 2px;
      margin: auto;
      color: #000000;
      text-shadow: 0px 0px 2px #ffffff;
      line-height: 1.3em;
      background: rgba(255,255,255,0.6);
      padding: 2px 4px;
    }
  }

  // avoid relocation roblems on markers
  .accommodation.icon.glyphicons {
    position: absolute !important;
  }
  
  // compass button
  .mapboxgl-ctrl-compass {
    display: none;

    &.show {
      display: block;
    }
  }
}

/*
puntos. Se aplica su aspecto desde el css
 */
.localidad {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(241,111,92,1);
  background: radial-gradient(ellipse at center, rgba(255,192,181,1) 0%, rgba(241,111,92,1) 45%, rgba(189,16,0,1) 100%);
  background-position-x: -5px;
  background-position-y: -5px;
}

.button_places {
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.button_places.show {
  background-image: url("../icons/icon_place_show.svg");
}

.button_places.hide {
  background-image: url("../icons/icon_place_hide.svg");
  display: block;
}

.mapboxgl-ctrl-top-right {
  .mapboxgl-ctrl button, .button {
    width: 40px !important;
    height: 40px !important;
  }
}




/**
 * Mobile: we hide zoom buttons
 */
.mobile .mapComponent {
  .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out {
    display: none;
  }

  .buttonBar {
    top: 60px;
  }
}
