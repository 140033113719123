/**
 * Servicio.scss
 */
@import '../Variables';

.servicio {
    display: inline-block;

    .icon.glyphicons {
        font-size: 20px;
        color: $gronze-gray;
        margin-right: 10px;
    }
    
    .glyphicons-svg {
      width: 20px;
      height: 20px;
      background-size: 24px 24px;
      //background-color: #999999;
      // Filter calculated by https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(61%) sepia(2%) saturate(18%) hue-rotate(330deg) brightness(100%) contrast(88%);
    }

}
