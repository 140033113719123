/*
Page info view
 */
@import '../Variables';

.gronzePage {
	background-color: white;
	width: 100vw;
	height: 100dvh;
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
}

.pageCard {

}
